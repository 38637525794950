import { FETCH_ALL_PREDICTIONS } from "graphql/landing";
import { unAuthorizedPost } from "services";
import { RESET_ALL_PREDICTIONS } from "store/types";
import { LIST_ALL_PREDICTIONS } from "store/types";
import { TOGGLE_LOADING } from "store/types";



export const listAllPrdictions = (requestData) => {
    return async (dispatch) => {
        dispatch({ type: TOGGLE_LOADING, payload: true });

        let apiResponse = await unAuthorizedPost("https://api-quizapp.funq.in/graphql/trader", {
            query: FETCH_ALL_PREDICTIONS,
            variables: requestData,
        });
        
        if (apiResponse.data.customStatus) {
            let {
                list_all_companies: { all_companies },
            } = apiResponse.data.data;

            dispatch({
                type: LIST_ALL_PREDICTIONS,
                payload: all_companies,
            });
        }
        dispatch({ type: TOGGLE_LOADING, payload: false });
    };
};

export const resetAllPrdictions = () => {
    return async (dispatch) => {
        dispatch({ type: RESET_ALL_PREDICTIONS });
    };
};