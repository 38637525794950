import * as React from "react";
import { SportsEsports, Store, Group, MonetizationOn, Public, Business } from "@material-ui/icons";
import {
    Timeline,
    TimelineConnector,
    TimelineContent,
    TimelineDot,
    TimelineItem,
    TimelineOppositeContent,
    TimelineSeparator,
} from "@material-ui/lab";

const styles = {
    toEnd: {
        height: "80px",
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end"
    },
    toStart: {
        display: "flex",
        // alignItems:"center"
    }
}

export default function CustomizedTimeline() {
    const renderRightAligned = (date, title, icon, isCurrent) => {
        return (
            <TimelineItem>
                <TimelineOppositeContent style={styles.toEnd}>
                    <em><small> {date} </small></em>
                </TimelineOppositeContent>
                <TimelineSeparator>
                    <TimelineConnector />
                    <TimelineDot color={isCurrent ? "primary" : "grey"}>
                        {icon}
                    </TimelineDot>
                    <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent style={styles.toStart}>
                    <b>{title}</b>
                </TimelineContent>
            </TimelineItem>
        )
    }
    const renderLeftAligned = (date, title, icon, isCurrent) => {
        return (
            <TimelineItem>
                <TimelineOppositeContent style={styles.toEnd}>
                    <b> {title} </b>
                </TimelineOppositeContent>
                <TimelineSeparator>
                    <TimelineConnector />
                    <TimelineDot color={isCurrent ? "primary" : "grey"}>
                        {icon}
                    </TimelineDot>
                    <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent style={styles.toStart}>
                    <em><small> {date} </small></em>
                </TimelineContent>
            </TimelineItem>
        )
    }

    return (
        <>

            <Timeline position="alternate">
                {renderRightAligned("january 2023", "Launch the game", <SportsEsports />, true)}
                {renderLeftAligned("march 2023", "Launch membership NFT marketplace", <Store />, false)}
                {renderRightAligned("may 2023", "Provide public access", <Group />, false)}
                {renderLeftAligned("july 2023", "Carry out ICO", <MonetizationOn />, false)}
                {renderRightAligned("september 2023", "Metaverse launch", <Public />, false)}
                {renderLeftAligned("december 2023", "Enable financial business to buy space in metaverse", <Business />, false)}
            </Timeline>
        </>
    );
}
