import React from 'react';
import "./navBar.css";

function NavBar() {
    return (
        <>
            <nav>
                <div className="container">
                    <input id="nav-toggle" type="checkbox" />
                    <div className="logo"><strong><a href='/'>funq</a></strong></div>
                    <ul className="links">
                        <li><a href="/">home</a></li>
                        <li><a href="https://nft.funq.club/auth/listings">listings</a></li>
                        <li><a href="https://play.funq.club/"><button>login</button></a></li>
                    </ul>
                    <label htmlFor="nav-toggle" className="icon-burger">
                        <div className="line" />
                        <div className="line" />
                        <div className="line" />
                    </label>
                </div>
            </nav>
        </>
    )
}

export default NavBar