export const FETCH_ALL_PREDICTIONS = `query ($countryExchange: String!){
    list_all_companies(country_exchange: $countryExchange){
      message
      all_companies{
        scrip_name
        company_name
        cmp
        target
        signal
        date
        lots_available
      }
    }
  }
  `;