import { Grid } from "@material-ui/core";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { resetAllPrdictions, listAllPrdictions } from "store/actions";
import { logoutUser } from "store/actions";
import Footer from "views/shared/footer";
import NavBar from "./navBar";
import "./style.css";
import CustomizedTimeline from "./timeline";

const buttonStyle = {
    marginLeft: "18px",
    backgroundColor: "#F9CA26",
    color: "black",
    border: 0,
};

function Landing() {
    const dispatch = useDispatch();

    const { predictionsList } = useSelector(({ landing: { predictionsList } }) => ({
        predictionsList,
    }));

    useEffect(() => {
        dispatch(logoutUser());
        dispatch(resetAllPrdictions());
        dispatch(listAllPrdictions({ countryExchange: "usa_dj" }));
        // eslint-disable-next-line
    }, []);

    const openLink = (link) => {
        window.open(link, "_self")
    }

    return (
        <div>
            <NavBar />

            <section className="container-fluid banner-container">
                <div className="container">
                    <div className="row banner-row">
                        <div className="col-md-6 banner-txt">
                            <h1>Funq is an AI based learn-to-earn game on the Metaverse.</h1>
                            <p>
                                This is a financial market-based game that synchronizes with the
                                real-world and the metaverse.
                            </p>
                            <p>
                                In this game AI provides multiple predictions that will happen in
                                the financial markets within the next few hours. Users can play
                                against the machine using their tokens.
                            </p>
                            <div className="btn-row row">
                                <button
                                    className="btn btn-outline-primary clr"
                                    style={{ marginLeft: "18px", color: "#F9CA26" }}
                                    onClick={() => openLink("https://nft.funq.club/auth/listings")}
                                >
                                    View marketplace
                                </button>
                                <button
                                    className="btn btn-primary clr"
                                    style={buttonStyle}
                                    onClick={() => openLink("https://play.funq.club/")}
                                >
                                    Play demo
                                </button>
                            </div>
                        </div>
                        <div className="col-md-6 banner-img">
                            <iframe
                                className="video"
                                src="https://www.youtube.com/embed/Akh4URVg8Zk?playlist=Akh4URVg8Zk&loop=1&autoplay=1"
                                title="video player"
                                allow="autoplay; picture-in-picture"
                                allowFullScreen
                            />
                            {/* <img src="assets/images/play-funq-1.png" alt="" /> */}
                        </div>
                    </div>
                </div>
            </section>

            <CustomizedTimeline />

            <section className="predictions-cointainer">
                <div className="container">
                    <div className="title">
                        <h4>
                            <b>Predictions</b>
                        </h4>
                        {predictionsList.length ? (
                            <p>
                                Here the <b>name of the company</b>, it's{" "}
                                <b>current market price,</b> and AI system predicted{" "}
                                <b>target price</b>s are mentioned respectively, under{" "}
                                <b>USA,Dow Jones</b> on <b>{predictionsList[0]?.date}.</b>
                            </p>
                        ) : null}
                    </div>
                    {predictionsList.length ? (
                        <Grid container justifyContent="center" spacing={1}>
                            {predictionsList.map((e, i) => (
                                <Grid item lg={6} xs={12} key={i}>
                                    <div className="element">
                                        <h3>{i + 1}</h3>
                                        <h4>{e.company_name}</h4>
                                        <div className="values">
                                            <ul>
                                                <li>{e.cmp}</li>
                                                <li>
                                                    <i className="fas fa-long-arrow-alt-right" />
                                                </li>
                                                <li 
                                                    className="target" 
                                                    style={{color: e.signal === "BUY" ? "forestgreen" : "red"}}
                                                >{e.target}</li>
                                            </ul>
                                        </div>
                                    </div>
                                </Grid>
                            ))}
                            <Grid item lg={12} xs={12}>
                                <div className="btn-row">
                                    <button onClick={() => openLink("https://play.funq.club/")}>
                                        play now
                                    </button>
                                </div>
                            </Grid>
                        </Grid>
                    ) : (
                        <div className="empty">
                            <p>No data found</p>
                        </div>
                    )}
                </div>
            </section>

            <Footer />
        </div>
    );
}

export default Landing;
