import { LIST_ALL_PREDICTIONS, RESET_ALL_PREDICTIONS } from "../types";

const INITIAL_STATE = {
    predictionsList: [],
};

const landingReducer = (state = INITIAL_STATE, { type, payload }) => {
    switch (type) {
        case LIST_ALL_PREDICTIONS:
            return {
                ...state,
                predictionsList: payload,
            };

        case RESET_ALL_PREDICTIONS:
            return {
                ...state,
                predictionsList: INITIAL_STATE.predictionsList,
            };

        default:
            return state;
    }
};

export default landingReducer;
